<template>
  <b-card
    no-body
    class="mb-0 mt-1"
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

      </b-row>
    </div>

    <b-table
      ref="refProvvigioniListTable"
      class="position-relative mh-200"
      :items="fetchProvvigioni"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Nessuna provvigione trovata"
      :sort-desc.sync="isSortDirDesc"
    >
      <!-- Column: Code -->

      <template #cell(periodo)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.year }}/{{ data.item.month }}</span>
        </div>
      </template>

      <template
        v-if="$can('users', 'Users')"
        #cell(utente)="data"
      >
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.user.fullName }}</span>
          <span class="text-muted d-block">
            {{ data.item.user.code }}
          </span>
        </div>
      </template>

      <template #cell(commissionabile)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.tot_commissions }}€</span>
        </div>
      </template>

      <template #cell(autoconsumo_personale)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.my_selfconsumption }}</span>
        </div>
      </template>

      <template #cell(ordini_extra)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.my_extra }}</span>
        </div>
      </template>

      <template #cell(kit_personale)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.my_kit }}</span>
        </div>
      </template>

      <template #cell(primo_ordine_personale)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.my_first_order }}</span>
        </div>
      </template>

      <template #cell(autoconsumi_rete)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.vpc_selfconsumption }}</span>
        </div>
      </template>

      <template #cell(extra_rete)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.vpc_extra }}</span>
        </div>
      </template>

      <template #cell(kit_rete)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.vpc_kit }}</span>
        </div>
      </template>

      <template #cell(incaricati_iscritti)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.incaricati_sub }}</span>
        </div>
      </template>

      <template #cell(primi_ordini_rete)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.vpc_first_order }}</span>
        </div>
      </template>

      <template #cell(clienti_iscritti)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.clienti_sub }}</span>
        </div>
      </template>

      <template #cell(gamba_sinistra)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.vpc_left }}</span>
        </div>
      </template>

      <template #cell(gamba_destra)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.vpc_right }}</span>
        </div>
      </template>

      <template #cell(riporto)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.carry_over }}</span>
        </div>
      </template>

      <template #cell(totale)="data">
        <div class="text-nowrap">
          <span class="align-text-top text-capitalize">{{ data.item.total }}</span>
        </div>
      </template>

    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="total"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BPagination,
  // BLink,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import useVpcsList from '@/views/provvigioni/useVpcsList'

export default {
  name: 'ProvvigioniTable',
  components: {
    // NewItem,
    BCard,
    BRow,
    BCol,
    BTable,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
  },
  props: {},
  watch: {},
  mounted() {
  },
  setup() {
    const {
      // Options
      quantityOptions,
      //
      fetchProvvigioni,
      refetchProvvigione,
      getListProvvigioni,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProvvigioniListTable,
      refetchData,

      // Extra Filters
      //
      statusNameResolver,

      tableColumnsResolver,

    } = useVpcsList()

    tableColumnsResolver()

    return {
      // Options
      quantityOptions,
      //
      fetchProvvigioni,
      refetchProvvigione,
      getListProvvigioni,
      tableColumns,
      perPage,
      currentPage,
      total,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refProvvigioniListTable,
      refetchData,

      // Extra Filters
      //
      statusNameResolver,

      tableColumnsResolver,
    }
  },
  methods: {},
}
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped/>
