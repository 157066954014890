<template>
  <provvigioni-table />
</template>

<script>
import ProvvigioniTable from '@/views/provvigioni/provvigioni-list/ProvvigioniTable.vue'

export default {
  name: 'Provvigioni',
  components: {
    ProvvigioniTable,
  },
}
</script>

<style scoped>

</style>
