import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { can } from '@core/libs/acl/utils'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refProvvigioniListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'periodo',
      value: 'created_at',
      sortable: true,
    },
    {
      key: 'commissionabile',
      value: 'tot_commissions',
      sortable: true,
    },
    {
      key: 'autoconsumo_personale',
      value: 'my_selfconsumption',
      sortable: true,
    },
    {
      key: 'ordini_extra',
      value: 'my_extra',
      sortable: true,
    },
    {
      key: 'kit_personale',
      value: 'my_kit',
      sortable: false,
    },
    {
      key: 'primo_ordine_personale',
      value: 'my_first_order',
      sortable: false,
    },
    {
      key: 'autoconsumi_rete',
      value: 'vpc_selfconsumption',
      sortable: true,
    },
    {
      key: 'extra_rete',
      value: 'vpc_extra',
      sortable: true,
    },
    {
      key: 'kit_rete',
      value: 'vpc_kit',
      sortable: true,
    },
    {
      key: 'incaricati_iscritti',
      value: 'incaricati_sub',
      sortable: true,
    },
    {
      key: 'primi_ordini_rete',
      value: 'vpc_first_order',
      sortable: true,
    },
    {
      key: 'clienti_iscritti',
      value: 'clienti_sub',
      sortable: true,
    },
    {
      key: 'gamba_sinistra',
      value: 'vpc_left',
      sortable: true,
    },
    {
      key: 'gamba_destra',
      value: 'vpc_right',
      sortable: true,
    },
    {
      key: 'riporto',
      value: 'carry_over',
      sortable: true,
    },
    {
      key: 'totale',
      value: 'total',
      sortable: true,
    },
  ]

  const quantityOptions = [1, 2, 3, 4, 5, 10]

  const perPage = ref(25)
  const total = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localUsersCount = refProvvigioniListTable.value ? refProvvigioniListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localUsersCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localUsersCount,
      of: total.value,
    }
  })

  const fetchProvvigioni = (ctx, callback) => {
    let sorterResolved = sortBy.value
    if (sorterResolved !== 'id' && tableColumns.find(key => key.key === sorterResolved).value !== sorterResolved) {
      sorterResolved = tableColumns.find(key => key.key === sorterResolved).value
    }
    store
      .dispatch('vpcs/fetchProvvigioni', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sorterResolved,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { vpcs, meta } = response.data.data
        if (callback) {
          callback(vpcs)
        }
        total.value = meta.total
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento ordini',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    if (refProvvigioniListTable.value) {
      refProvvigioniListTable.value.refresh()
    } else {
      fetchProvvigioni()
    }
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const refetchProvvigione = () => {
    store.dispatch('vpcs/fetchProvvigione', { id: router.currentRoute.params.id })
      .then(() => {
      })
      .catch(error => {
        console.log(error.response)
        toast({
          component: ToastificationContent,
          props: {
            title: 'Problemi nel caricamento utenti',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const getListProvvigioni = () => store.getters['vpcs/getListProvvigioni']

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const statusNameResolver = currentStatusName => {
    if (currentStatusName === 'Pending') return 'In Sospeso'
    if (currentStatusName === 'To Pay') return 'Da Pagare'
    if (currentStatusName === 'Payed') return 'Pagato'
    if (currentStatusName === 'Sended') return 'Spedito'
    return 'secondary' // Se archived
  }

  const tableColumnsResolver = () => {
    if (can('users', 'Users')) {
      // Inserisco la colonna utente che deve essere visibile solo dagli admin al posto con indice 1 dell'array che gestisce le colonne
      tableColumns.splice(1, 0, {
        key: 'utente',
        value: 'user_id',
        sortable: false,
      })
    }
  }

  return {
    // Options
    quantityOptions,
    //
    fetchProvvigioni,
    refetchProvvigione,
    getListProvvigioni,
    tableColumns,
    perPage,
    currentPage,
    total,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProvvigioniListTable,
    refetchData,

    // Extra Filters
    //
    statusNameResolver,
    tableColumnsResolver,
  }
}
